.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: white;
  background-image: url("/public/homepage-background.jpg");
  background-size: cover;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* 
@font-face {
  font-family: "Misto";
  src: local("Misto"), url(./fonts/Misto.woff) format("woff");
}
@font-face {
  font-family: "Dinomouse-Regular";
  src: local("Dinomouse-Regular"),
    url(./fonts/Dinomouse-Regular.otf) format("opentype");
}
@font-face {
  font-family: "Anggoro-Regular";
  src: local("Anggoro-Regular"),
    url(./fonts/Anggoro-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Fabulist";
  src: local("Fabulist"), url(./fonts/Fabulist.otf) format("opentype");
} */
